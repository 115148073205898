// noinspection JSUnresolvedReference

import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import * as Sentry from "@sentry/react"
import { App } from "./view/App/App"
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom"

import "./old/application"
import "./submodule/core/js/main.js"
import "./submodule/core/js/form/fieldModule"
import "./submodule/core/js/form/formModule"

import "./submodule/core/js/vendor/select2/select2-4.0.3" // js je upraveny, proto ne min
import "./../node_modules/select2/dist/js/i18n/en"
import "./../node_modules/select2/dist/js/i18n/sk"
import "./../node_modules/select2/dist/js/i18n/cs"
import "./submodule/crm/user_js/form/RelationFormModule"
import "./submodule/crm/user_js/relation/RelationSelectModule"

const isProduction = (): boolean => {
	return ( !process.env.NODE_ENV || process.env.NODE_ENV === "development" ) == false
}

if( isProduction() && process.env.REACT_APP_ENVIRONMENT !== "development" ) {
	Sentry.init( {
		dsn: "https://4fefb04b70b14332bef043e2330e859c@o514522.ingest.sentry.io/5631619",
		integrations: [
			new Sentry.BrowserTracing( {
				routingInstrumentation: Sentry.reactRouterV6Instrumentation(
					React.useEffect,
					useLocation,
					useNavigationType,
					createRoutesFromChildren,
					matchRoutes,
				),
			} ),
			new Sentry.Replay(),
		],
		tracesSampleRate: 0.5,
		// Capture Replay for 10% of all sessions,
		// plus for 100% of sessions with an error
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	} )
}

const root = ReactDOM.createRoot(
	document.getElementById( 'app' ) as HTMLElement,
)
root.render(
	<App />,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
