import * as React from "react"
import { CSSProperties, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import ListItemMenu from "../list/ListItemMenu"
import { PermissionData } from "../Permission"
import { MobileLinkFactory } from "../MobileLinkFactory"
import { FIRM_GLOBAL_VIEW } from "../helper/ViewHelper"
import { ListItemCallbackProps } from "./MobileListDataWrapper"

export type ListItemWrapperProps = {
	name: string, table: string,
	permission: PermissionData,
	id: number,
	isClosed?: boolean,
	itemBasicElement?: React.ReactNode,
	itemExpandLeftElement?: React.ReactNode,
	itemExpandRightElement?: React.ReactNode,
	style: CSSProperties,
	tagColor?: string,
	link?: string,
	index: number,
} & ListItemCallbackProps;

export const ListItemWrapper = ( props: ListItemWrapperProps ) => {
	const link = props.link == null ? MobileLinkFactory.createFormLink( props.table, props.id ) : props.link
	const navigate = useNavigate()
	const [ menuVisible, setMenuVisible ] = useState<boolean>( false )

	return (
		<div style={ props.style }>
			<div className={ "listItem" }>
				<div className={ "listItemContentWrapper" } style={ { borderLeft: "5px solid " + ( props.tagColor || "white" ) } }>
					<div onClick={ () => {
						if( props.permission.canEdit ) {
							navigate( link )
						}
						return false
					} } className={ "itemContentLeft" }>
						{
							props.permission.canEdit
								? <div className={ "itemName" }>
									<Link to={ link }>
										{ props.name }
									</Link>
								</div>
								: <div className={ "itemName" }>
									{ props.name }
								</div>
						}
						{ props.itemExpandLeftElement }
					</div>
					<div className={ "itemContentRight" }>
						{ props.itemBasicElement }
						{ props.itemExpandRightElement }
					</div>
				</div>
				{ props.table != FIRM_GLOBAL_VIEW &&
					<ListItemMenu
						isClosed={ props.isClosed }
						permission={ props.permission } id={ props.id }
						table={ props.table } index={ props.index }
						onDeleteItemCallback={ props.onDeleteItemCallback }
						onEditItemCallback={ props.onEditItemCallback }
						onListMessageCallback={ props.onListMessageCallback }
						onLoadingStart={ props.onLoadingStart }
						menuVisible={ menuVisible } setMenuVisible={ setMenuVisible }
					/>
				}
			</div>
		</div>
	)
}
