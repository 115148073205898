import * as React from "react"
import { useEffect, useState } from "react"
import { AxiosResponse } from "axios"
import MobileCrmData from "../MobileCrmData"
import ReactPlaceholder from "react-placeholder"
import { crmApi } from "../api/CrmApi"
import { MobileLinkFactory } from "../MobileLinkFactory"
import $ from 'jquery'
import { FormExecutor } from "./FormExecutor"
import { useNavigate } from "react-router-dom"
import { Alert, Snackbar } from "@mui/material"
import { FormattedMessage } from "react-intl"
import PageSpinner from "../helper/PageSpinner"
import { AlertColor } from "@mui/material/Alert"

export type FormDataResponse = {
	formId: number,
	extHtml: string,
	javaScript: string,
	instanceToken: string,
	status?: string, //after save
}

const FormDataLoader = ( { table, id, firmDbId }: {
	table?: string,
	id?: number,
	firmDbId?: number
} ) => {
	const [ formId, setFormId ] = useState<number | null>( null )
	const [ alertSeverity, setAlertSeverity ] = React.useState<AlertColor>( 'success' )
	const [ alertMessageId, setAlertMessageId ] = React.useState<string | undefined>( undefined )
	const [ loading, setLoading ] = React.useState<boolean>( false )

	const navigate = useNavigate()

	function deleteFormId() {
		console.log( "deleting formId", MobileCrmData.lastFormId )
		delete window[ MobileCrmData.lastFormId ]
		// @ts-ignore
		delete window[ MobileCrmData.lastFormId + "_relationFormModule" ]
	}

	if( MobileCrmData.lastFormId && loading === false ) {
		deleteFormId()
	}

	useEffect( () => {
		const config = {
			"params": {
				"table": table,
				"id": id,
				"firmDbId": firmDbId,
			},
		}
		crmApi.get( MobileLinkFactory.createActionLink( MobileCrmData.FORM_ACTION ), config ).then( ( data: AxiosResponse<FormDataResponse> ) => {
			setFormId( data.data.formId )
			setTimeout( () => {
				FormExecutor( data.data, "mobileForm" )

				if( table ) {
					// @ts-ignore
					window[ data.data.formId ].mobileSaveCallback = ( response: AxiosResponse ) => {
						//call after success submit
						// console.log( 'save callback ', response.data )
						const status = response?.data?.status
						if( status === false ) {
							setAlertSeverity( 'error' )
							setAlertMessageId( "formSavedFail" )
						} else {
							setAlertMessageId( 'formSaved' )
						}
						deleteFormId()
						setTimeout( () => {
							navigate( MobileLinkFactory.createListLink( table ) )
						}, 1000 )
					}
					// @ts-ignore
					window[ data.data.formId ].mobileBeforeSubmitCallback = () => {
						setLoading( true )
					}
					// @ts-ignore
					window[ data.data.formId ].mobileDeleteCallback = ( response: AxiosResponse ) => {
						// setLoading( true )
						const status = response?.data?.status
						if( status === false ) {
							setAlertSeverity( 'error' )
							setAlertMessageId( "formEntityDeleteFail" )
						} else {
							setAlertMessageId( 'formEntityDelete' )
						}
						deleteFormId()
						setTimeout( () => {
							navigate( MobileLinkFactory.createListLink( table ) )
						}, 1000 )
					}
				}

				const $formTopButtons = $( "#formTopButtons" )
				$formTopButtons.html( "" )
				const submitClone = $( "div.fSubmit" ).clone()
				// noinspection JSUnresolvedReference
				submitClone.appendTo( $formTopButtons )
			}, 100 )
		} ).catch( ( error ) => {
			console.log( error.message )
		} )
	}, [ id, table, firmDbId, navigate ] )

	const handleClose = ( _event?: React.SyntheticEvent | Event, reason?: string ) => {
		if( reason === "clickaway" ) {
			return
		}

		setAlertMessageId( undefined )
		setAlertSeverity( 'success' )
	}

	return ( <ReactPlaceholder ready={ formId !== null } rows={ 15 } type={ "text" } showLoadingAnimation>
		<div id={ "mobileForm" } />
		{
			loading && <PageSpinner />
		}
		<Snackbar open={ alertMessageId !== undefined } autoHideDuration={ 3000 } onClose={ handleClose }
				  anchorOrigin={ { horizontal: "center", vertical: "bottom" } }>
			<Alert severity={ alertSeverity } sx={ { width: "100%" } } onClose={ handleClose }>
				<FormattedMessage defaultMessage={ "Formulář byl uložen" } id={ alertMessageId } />
			</Alert>
		</Snackbar>
	</ReactPlaceholder> )
}

export default FormDataLoader