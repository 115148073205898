import * as React from "react"
import { Link } from "react-router-dom"
import { EntityBasicData } from "../EntityType"
import { MobileLinkFactory } from "../MobileLinkFactory"

export type SummaryListData = EntityBasicData & {
	statusName?: string,
	statusColor?: string,
	isClosed?: boolean
}

export type SummaryListItemDataProps = {
	data: SummaryListData, table: string
};

const SummaryListItem = ( props: SummaryListItemDataProps ) => {
	return <div className={ "summaryListItem" }>
		<div className={ "itemBasic" }>
			{
				props.data.permission.canEdit ?
					<span className={ "itemName" }>
						<Link key={ "/mobileForm" } to={ MobileLinkFactory.createFormLink( props.table, props.data.id ) }>
							{ props.data.name }
						</Link>
					</span> :
					<span className={ "itemName" }>{ props.data.name }</span>
			}
			{
				props.data.firmName ?
					<span className={ "itemFirm" }>{ props.data.firmName }</span> : ""
			}
			{
				props.data.statusName ?
					<span className={ "itemState" } style={ { color: props.data.statusColor } }>
						{ props.data.statusName }
					</span> : ""
			}
		</div>

	</div>
}

export default SummaryListItem
