export const TYPE_MOBILE = "mobile"
export const TYPE_PHONE = "phone"
export const TYPE_MAIL = "email"
export const TYPE_MESSENGER = "im"
export const TYPE_WEB = "www"

type PermissionData = {
	canEdit: boolean,
	canShare: boolean,
	canDelete: boolean,
	ownerId: number,
	assignedUserId: number[],
	isClosable: boolean
};

export type EntityBasicData = { id: number, name: string, description: string, permission: PermissionData, firmName: string, entityTable: string }

export type OpportunityData = EntityBasicData & {
	typeName: string,
	statusName: string,
	statusColor: string,
	isClosed:boolean,
	priorityName: string,
	person: string[],
	dateNextAction: string,
};

export type TaskData = EntityBasicData & {
	dateTo: string,
	dateFrom: string,
	person: string[],
	statusName: string,
	statusColor: string,
	isClosed:boolean,
	priorityName: string,
};

export type MeetingData = EntityBasicData & {
	dateTo: string,
	dateFrom: string,
	person: string[],
	statusName: string,
	statusColor: string,
	isClosed:boolean,
	priorityName: string
};

export type OrderData = EntityBasicData & {
	totalPrice: string,
	dateOrder: string,
	datePaid: string,
	statusName: string,
	typeName: string,
	statusColor: string,
	isClosed:boolean,
	priorityName: string
};

export type ProjectData = EntityBasicData & {
	projectNumber: string,
	dateTo: string,
	person: string[],
	statusName: string,
	statusColor: string,
	isClosed:boolean,
	priorityName: string
};

export type CalendarData = EntityBasicData & {
	dateTo: string,
	dateFrom: string,
	statusColor: string,
};

export type FirmCustomerRelationData = {
	relationType: string,
	productCategory: string
};

export type FirmCustomerData = EntityBasicData & {
	city: string
	relation: FirmCustomerRelationData[]
	phone: string[]
	revenue: string
	webAddress: string
	cooperationState: string
	cooperationStateCode: string
};

export type FirmDbCountryData = FirmCustomerData & {
	firmDbId: number,
	idNumber: string,
	workerCount: string,
	mainAddress: string,
	facilityAddress: string,
	dateCreated: string,
	legalForm: string
}

export type PersonData = EntityBasicData & {
	departmentName: string,
	firmDbId: number,
	firmCustomerId: number,
	personContactEntries: ContactData[]
};

export type ContactData = EntityBasicData & {
	contact: string,
	contactType: string,
	contactCountry: string,
	firmDbId: number,
	firmCustomerId: number
};