import app from "../old/application"
import MobileCrmData from "../MobileCrmData"
import { RelationFormModule } from "../submodule/crm/user_js/form/RelationFormModule"
import { FieldModule } from "../submodule/core/js/form/fieldModule"
import { FormModule } from "../submodule/core/js/form/formModule"
import { RelationSelectModule } from "../submodule/crm/user_js/relation/RelationSelectModule"
import { FormDataResponse } from "./FormDataLoader"

export function FormExecutor( data: FormDataResponse, htmlId: string ) {
	const htmlElement: HTMLElement | null = document.getElementById( htmlId )
	if( htmlElement ) {
		htmlElement.innerHTML = data.extHtml
	}

	// @ts-ignore
	window.CrmMain = {
		RelationFormModule: RelationFormModule,
		RelationSelectModule: RelationSelectModule,
		FormModule: FormModule,
		FieldModule: FieldModule,
	}
	// @ts-ignore
	window.CoreJs = {
		FormModule: FormModule,
		FieldModule: FieldModule,
	}

	//todo vz tady je potreba vymazat onload protoze poras pribyva
	app.data.onload = []

	eval.call( window, data.javaScript )

	if( app.data.onload ) {
		for( let i = 0; i < app.data.onload.length; i++ ) {
			app.data.onload[ i ]()
		}
	}

	if( data.formId != MobileCrmData.lastFormId ) {
		MobileCrmData.lastFormId = data.formId
	}

	// if( app.data.onunload ) {
	// 	$( window ).unload( function() {
	// 		for( let i = 0; i < app.data.onunload.length; i++ ) {
	// 			app.data.onunload[ i ]()
	// 		}
	// 	} )
	// }
}