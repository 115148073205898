import * as React from "react"
import { useEffect, useState } from "react"
import { IndexRange } from "react-virtualized"
import MobileCrmData from "../MobileCrmData"
import { MobileListView, REQUEST_THRESHOLD } from "./MobileListView"
import { EntityBasicData } from "../EntityType"
import { NoDataWrapper } from "../NoDataWrapper"
import { DataLoader } from "@CrmCommon/loader/DataLoader"
import { Filter, IFilter, QuickFilter, SortFilter } from "../form/Filter"
import { filterDataToMap, getListFilterFormInstanceToken } from "../form/FilterFormDataLoader"
import { FormattedMessage } from "react-intl"
import { FIRM_GLOBAL_VIEW } from "../helper/ViewHelper"
import { AlertColor } from "@mui/material/Alert"

type ListData = EntityBasicData[];

type MobileFetchProps = { table: string, range: IndexRange, searchString?: string }

let activeFilter = false

type ListFetchData = { rows: ListData }

/**
 * 	/getListData?params={"table":"project","startIndex":0,"stopIndex":30,"orderColumn":"id","orderType":"desc","quickFilter":true,"searchString":"cina","permFilter":"my"}
 * 	varianta s quickFilter=true - znamená ze se používá quickFilter
 * 	volitelně lze použít:
 * 		orderColumn,orderType
 * 		searchString
 * 		permFilter (my)
 */
async function fetchData( props: MobileFetchProps ): Promise<ListFetchData> {
	const action = props.table == "firmGlobalView" ? MobileCrmData.FIRM_LIST_ACTION : MobileCrmData.LIST_ACTION

	const params = {
		"table": props.table,
		"startIndex": props.range.startIndex,
		"stopIndex": props.range.stopIndex,
	} as Record<string, string | number | boolean> | IFilter

	let storedFilter = new Filter( props.table )
	if( Object.keys( storedFilter.filter ).length === 0 ) {
		storedFilter = new QuickFilter( props.table )
		params.quickFilter = true
	}
	if( props.table !== FIRM_GLOBAL_VIEW ) {
		delete storedFilter.filter.countryCode
	}
	if( Object.keys( storedFilter.filter ).length > 0 ) {
		activeFilter = true
		const instanceToken: string | null = getListFilterFormInstanceToken( props.table ) || null
		if( instanceToken !== null ) {
			params.instanceToken = instanceToken
		}
		params.formData = storedFilter.filter
	}
	const sortFilter = new SortFilter( props.table )

	filterDataToMap( sortFilter.filter ).forEach( ( value, key ) => {
		params[ key ] = value
	} )
	props.searchString && ( params.searchString = props.searchString )
	storedFilter.filter.permFilter && ( params.permFilter = storedFilter.filter.permFilter )
	console.log( "fetch params: ", params )

	// @ts-ignore
	return new DataLoader().fetchData( action, params )
}

export type MobileListDataWrapperProps = {
	table: string
	searchString?: string
	filter?: IFilter
}

export type ListDeleteCallback = ( index: number ) => void;
export type ListReloadCallback = () => void;
export type ListMessageCallback = ( message: string, type?: AlertColor ) => void;

export type ListItemCallbackProps = {
	onDeleteItemCallback: ListDeleteCallback
	onEditItemCallback?: ListReloadCallback
	onListMessageCallback?: ListMessageCallback,
	onLoadingStart?: ( status: boolean ) => void
}

export function MobileListDataWrapper( { table, searchString, filter }: MobileListDataWrapperProps ) {
	const [ listData, setListData ] = useState<ListData>( [] )
	const [ loading, setLoading ] = useState<boolean>( true )
	const [ reload, setReload ] = useState<boolean>( false )

	const addData = ( newData: ListData ) => {
		setListData( ( prevData ) => {
			newData.forEach( ( item ) => {
				if( !prevData.find( ( listItem ) => listItem.id === item.id ) ) {
					prevData.push( item )
				}
			} )
			return [ ...prevData ]
		} )
		setLoading( false )
	}

	useEffect( () => {
		setLoading( true )
		setListData( [] )
		handleFetchFeed( { startIndex: 0, stopIndex: REQUEST_THRESHOLD }, table, addData )
			.then( () => {
				setLoading( false )
				setReload( false )
			} )
			.catch( () => {
				setLoading( false )
				setReload( false )
			} )
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ searchString, table, filter, reload ] )

	async function handleFetchFeed( range: IndexRange, table: string, addListData: ( newData: ListData ) => void ): Promise<void> {
		try {
			const data = await fetchData( { table: table, range: range, searchString: searchString } )
			if( data.rows && Array.isArray( data.rows ) ) {
				addListData( data.rows )
			}
		}
		catch( reason ) {
			console.log( reason )
		}
	}

	if( listData.length === 0 && loading === false ) {
		return <>
			<NoDataWrapper />
			{ activeFilter ?
				<div className={ "activeFilter" }><FormattedMessage defaultMessage={ "Máte aktivní filtr" } id={ "filterIsActive" } /></div> : null }
		</>
	}

	if( table === FIRM_GLOBAL_VIEW && !searchString ) {
		return <div className="noDataToShow"><FormattedMessage defaultMessage={ "Zadejte název firmy" } id={ "enterSearchText" } /></div>
	}

	return <MobileListView
		handleFetchFeed={ ( range: IndexRange ) => handleFetchFeed( range, table, addData ) }
		listData={ listData }
		table={ table }
		deleteCallback={ ( index: number ) => {
			setListData( [ ...listData.slice( 0, index ), ...listData.slice( index + 1, listData.length ) ] )
		} }
		reloadCallback={ () => {
			setReload( true )
		} }
	/>
}