import * as React from "react"
import { CSSProperties, useState } from "react"
import { FirmDbCountryData } from "../EntityType"
import { ListItemWrapper } from "../list/ListItemWrapper"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone } from "@fortawesome/pro-solid-svg-icons/faPhone"
import { MobileDialog } from "../dialog/MobileDialog"
import { MobileLinkFactory } from "../MobileLinkFactory"
import { ListItemCallbackProps } from "../list/MobileListDataWrapper"

export type FirmListDataProps = {
	data: FirmDbCountryData, table: string,
	style: CSSProperties,
	index: number,
} & ListItemCallbackProps;

const ItemLeftSide = ( props: FirmListDataProps ) => {
	return <>
		<div style={ { marginBottom: ".5em" } }>
			{ props.data.city }
		</div>
		<span style={ { paddingRight: ".5em" } } className={ "cooperation_status_" + props.data.cooperationStateCode }>
			{ props.data.cooperationState }
		</span>
		<div className={ "ellipsis" } style={ {
			display: "inline", width: "2em", color: "#BBB",
		} }>
			{
				props.data.relation.map( ( relation, index ) => (
					<span key={ index } style={ { paddingRight: ".5em" } }>{ relation.relationType }</span>
				) )
			}
		</div>
	</>
}

const DialogContent = ( props: FirmListDataProps ) => {
	return <>
		{ props.data.phone.map( ( phone, index ) => {
			return phone.length > 3 ? <div key={ index }>
				<a style={ { color: "#FFF" } } href={ "tel:" + phone }>
					<FontAwesomeIcon style={ { color: "green" } }
									 icon={ faPhone } />
					{ phone }
				</a>
			</div> : null
		} ) }
	</>
}

const ItemRightSide = ( props: FirmListDataProps ) => {
	const [ showDialog, setShowDialog ] = useState<boolean>( false )
	if( props.data.phone.length === 1 && props.data.phone[ 0 ] === "Neuvedeno" ) {
		return null
	}

	if( props.data.phone.length === 1 ) {
		return ( <div className={ "callFirmWrapper" }>
			<div className={ "callFirm" }>
				<a style={ { color: "#6FD76F" } } href={ "tel:" + props.data.phone[ 0 ].toString() }>
					<FontAwesomeIcon icon={ faPhone } />
				</a>
			</div>
		</div> )
	}

	return <div className={ "callFirmWrapper" }>
		<div onClick={ () => {
			setShowDialog( true )
		} } className={ "callFirm" }>
			<FontAwesomeIcon icon={ faPhone } />
		</div>
		<MobileDialog showCallback={ setShowDialog } show={ showDialog } content={ <DialogContent { ...props } /> } title={ "" } />
	</div>
}

export const FirmDbCountryListItem = ( props: FirmListDataProps ) => {
	return (
		<ListItemWrapper
			name={ props.data.name } table={ props.table }
			permission={ props.data.permission } id={ props.data.firmDbId }
			itemExpandLeftElement={ <ItemLeftSide { ...props } /> }
			itemExpandRightElement={ <ItemRightSide { ...props } /> }
			link={ MobileLinkFactory.createDetailLink( props.table, props.data.firmDbId ) }
			style={ props.style } index={ props.index }
			onDeleteItemCallback={ props.onDeleteItemCallback }
			onListMessageCallback={ props.onListMessageCallback }
			onLoadingStart={props.onLoadingStart}
		/>

	)
}
