import * as React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from '@fortawesome/pro-solid-svg-icons'

export type ListItemStateContentProps = {
	statusColor: string,
	statusName: string,
	isClosed?: boolean
};

export const ListItemStateContent = ( props: ListItemStateContentProps ) => {
	return <div className={ "itemState" }
				style={ { color: props.statusColor } }>
		{ props.statusName }
		{ props.isClosed && <FontAwesomeIcon icon={ faCheck } style={ { marginLeft: "5px" } } /> }
	</div>
}