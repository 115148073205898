import * as React from "react"
import { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import { PermissionData } from "../Permission"
import { MobileLinkFactory } from "../MobileLinkFactory"
import MobileCrmData from "../MobileCrmData"
import { MobileConfirmDialog } from "../dialog/MobileConfirmDialog"
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons/faEllipsisV'
import { FormattedMessage } from "react-intl"
import { crmApi } from "../api/CrmApi"
import { AxiosResponse } from "axios"
import { ListItemCallbackProps } from "./MobileListDataWrapper"

export type ListItemMenuData = {
	permission?: PermissionData,
	isClosed?: boolean,
	table: string,
	id: number,
	index: number,
	menuVisible: boolean, setMenuVisible: ( menuVisible: boolean ) => void
} & ListItemCallbackProps

type EntityDeleteResponse = { status: boolean }
type EntityCloseResponse = { status: boolean, message?: string }

const ListItemMenu = ( props: ListItemMenuData ) => {
	const [ showDeleteDialog, setShowDeleteDialog ] = useState<boolean>( false )
	const [ showCloseDialog, setShowCloseDialog ] = useState<boolean>( false )

	const canEdit = props.permission?.canEdit
	const canDelete = props.permission?.canEdit
	const isClosable = props.permission?.isClosable
	const isClosed = isClosable && props.isClosed

	const confirmDeleteCallback = () => {
		const config = {
			"table": props.table,
			"id": props.id,
		}
		if( props.onLoadingStart ) {
			props.onLoadingStart( true )
		}
		crmApi.post( MobileLinkFactory.createActionLink( MobileCrmData.DELETE_ENTITY_ACTION ), config )
			.then( ( response: AxiosResponse<EntityDeleteResponse> ) => {
				if( response?.data?.status ) {
					props.onDeleteItemCallback( props.index )
					if( props.onListMessageCallback ) {
						props.onListMessageCallback( 'Položka byla smazána', 'success' )
					}
				} else {
					if( props.onListMessageCallback ) {
						props.onListMessageCallback( 'Položku se nepodařilo smazat', 'warning' )
					}
				}

				if( props.onLoadingStart ) {
					props.onLoadingStart( false )
				}
			} ).catch( ( error ) => {
			if( props.onLoadingStart ) {
				props.onLoadingStart( false )
			}
			console.log( error.message )
		} )
	}
	const confirmCloseCallback = () => {
		const config = {
			"table": props.table,
			"id": props.id,
		}
		if( props.onLoadingStart ) {
			props.onLoadingStart( true )
		}
		crmApi.post( MobileLinkFactory.createActionLink( MobileCrmData.CLOSE_ENTITY_ACTION ), config )
			.then( ( response: AxiosResponse<EntityCloseResponse> ) => {
				if( response?.data?.status ) {
					if( props.onEditItemCallback ) {
						props.onEditItemCallback()
					}
					if( props.onListMessageCallback ) {
						props.onListMessageCallback( isClosed ? 'Položka byla otevřena' : 'Položka byla uzavřena', 'success' )
					}
				} else {
					if( props.onListMessageCallback && response.data?.message ) {
						props.onListMessageCallback( response.data.message, 'warning' )
					}
				}
				if( props.onLoadingStart ) {
					props.onLoadingStart( false )
				}
			} ).catch( ( error ) => {
			if( props.onLoadingStart ) {
				props.onLoadingStart( false )
			}
			console.log( error.message )
		} )
	}

	return (
		<>
			<div className={ "itemMenu" } onClick={ () => {
				props.setMenuVisible( !props.menuVisible )
				setTimeout( () => {
					props.setMenuVisible( false )
				}, 4000 )
			} }>
				{ canEdit ? <>
					<FontAwesomeIcon icon={ faEllipsisV } />
					{ props.menuVisible &&
						<div className={ "menu" } style={ { display: "inline-grid" } }>
						<span>
							<Link
								key={ props.table }
								to={ MobileLinkFactory.createFormLink( props.table, props.id ) }
							>
								<FormattedMessage defaultMessage={ "Upravit" } id={ "listItemEdit" } />
							</Link>
						</span>
							{ canDelete ? <span
								onClick={ () => {
									setShowDeleteDialog( true )
								}
								}
							>
							<FormattedMessage defaultMessage={ "Smazat" } id={ "listItemDelete" } />
						</span> : undefined }
							{ isClosable ? <span
								onClick={ () => {
									setShowCloseDialog( true )
								}
								}
							>
							<FormattedMessage defaultMessage={ isClosed ? "Otevřít" : "Uzavřít" } id={ "listItemClose" } />
						</span> : undefined }
						</div>
					}
				</> : undefined }
			</div>


			<MobileConfirmDialog
				show={ showDeleteDialog }
				confirmCallback={ confirmDeleteCallback }
				showCallback={ setShowDeleteDialog }
				message={ <FormattedMessage defaultMessage={ "Opravdu si přejete smazat záznam? " } id={ "listItemDeleteConfirm" } /> }
				confirmButtonText={ <FormattedMessage defaultMessage={ "Smazat" } id={ "listItemDelete" } /> }
			/>
			<MobileConfirmDialog
				show={ showCloseDialog }
				confirmCallback={ confirmCloseCallback }
				showCallback={ setShowCloseDialog }
				message={ <FormattedMessage defaultMessage={ "Přejete si " + ( isClosed ? "otevřít" : "uzavřít" ) + " tento záznam? " } id={ "listItemCloseConfirm" } /> }
				confirmButtonText={ <FormattedMessage defaultMessage={ isClosed ? "Otevřít" : "Uzavřít" } id={ "listItemClose" } /> }
			/>
		</>

	)
}

export default ListItemMenu
