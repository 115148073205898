import * as React from "react"
import { CSSProperties, useState } from "react"
import { FirmCustomerData } from "../EntityType"
import { ListItemWrapper } from "../list/ListItemWrapper"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone } from "@fortawesome/pro-solid-svg-icons/faPhone"
import { MobileDialog } from "../dialog/MobileDialog"
import { ListItemCallbackProps } from "../list/MobileListDataWrapper"
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {Link} from "react-router-dom";

export type FirmCustomerDataProps = {
	data: FirmCustomerData, table: string,
	style: CSSProperties,
	index: number,

} & ListItemCallbackProps;

const ItemLeftSide = ( props: FirmCustomerDataProps ) => {
	return <>
		<div style={ { marginBottom: ".5em" } }>
			{ props.data.city }
		</div>
		<span style={ { paddingRight: ".5em" } } className={ "cooperation_status_" + props.data.cooperationStateCode }>
			{ props.data.cooperationState }
		</span>
		<div className={ "ellipsis" } style={ {
			display: "inline", width: "2em", color: "#BBB",
		} }>
			{
				props.data.relation.map( ( relation, index ) => ( <span key={ index } style={ { paddingRight: ".5em" } }>{ relation.relationType }</span> ) )
			}
		</div>
	</>
}

const DialogContent = ( props: FirmCustomerDataProps ) => {
	return <>
		{ props.data.phone.map( ( phone, index ) => {
			return phone.length > 3 ? <div key={ index }>
				<a style={ { color: "#FFF" } } href={ "tel:" + phone }>
					<FontAwesomeIcon style={ { color: "green" } }
									 icon={ faPhone } />
					{ phone }
				</a>
			</div> : null
		} ) }
	</>
}

const ItemRightSide = ( props: FirmCustomerDataProps ) => {
	const [ showDialog, setShowDialog ] = useState<boolean>( false )
	if( props.data.phone.length === 1 && props.data.phone[ 0 ] === "Neuvedeno" ) {
		return null
	}

	if( props.data.phone.length === 1 ) {
		return ( <div className={ "callFirmWrapper" }>
			<div className={ "callFirm" }>
				<a style={ { color: "#6FD76F" } } href={ "tel:" + props.data.phone[ 0 ].toString() }>
					<FontAwesomeIcon icon={ faPhone } />
				</a>
			</div>
		</div> )
	}

	return <div className={ "callFirmWrapper" }>
		<div onClick={ () => {
			setShowDialog( true )
		} } className={ "callFirm" }>
			<FontAwesomeIcon icon={ faPhone } />
		</div>
		<MobileDialog showCallback={ setShowDialog } show={ showDialog } content={ <DialogContent { ...props } /> } title={ "" } />
	</div>
}

export const FirmCustomerListItem = ( props: FirmCustomerDataProps ) => {
	return (

		<ListItemWrapper
			name={ props.data.name } table={ props.table }
			permission={ props.data.permission } id={ props.data.id }
			itemExpandLeftElement={ <ItemLeftSide { ...props } /> }
			itemExpandRightElement={ <ItemRightSide { ...props } /> }
			style={ props.style } index={ props.index }
			onDeleteItemCallback={ props.onDeleteItemCallback }
			onEditItemCallback={ props.onEditItemCallback }
			onListMessageCallback={ props.onListMessageCallback }
			onLoadingStart={props.onLoadingStart}
		/>

	)
}
